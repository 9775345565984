import React, { useState } from 'react';

import { Modal, Button, Form, Input, Radio, Space } from "antd";
import { DeleteOutlined } from '@ant-design/icons';

const emailPatternRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EmailChangePopup = (props) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(props.visable);
  const [value, setValue] = useState('');
  const [emailList, setEmailList] = useState(['test@gmail.com', 'test2@gmail.com']);
  const [password, setPassword] = useState('');
  const [emailView, setEmailView] = useState(false);

  const onFinish = async (values) => {
    setEmailList(emailList => [...emailList, values.email]);
    form.setFieldValue('email', '');
  };

  const deleteItem = (index) => {
    const newItems = emailList.filter((_, i) => i !== index);
    setEmailList(newItems);
  };

  const onChange = (e) => {
    setValue(e.target.value);
    props.emailValue(e.target.value);
    setEmailView(true)
  };


  return (
    <div>
      <Modal
        open={open}
        closable={false}
        footer={null}
        width={450}
      >
        <div
          style={{
            textAlign: "center",
            marginTop: "140px",
            padding: "25px 0px",
          }}
        >
          <>
            {/* <h2>Confirmation</h2> */}
            <div style={{ fontSize: "18px", padding: "15px 15px" }}>
              Avaiable testing crediential listed below. Do you want to change email?
            </div>
            <div className='email-popup'>
              {/* EMAIL FORM AND LIST */}
              {
                !emailView && (
                  <>

                    <div className="form">
                      <Form
                        form={form}
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical"
                        initialValues={{
                          email: ''
                        }}
                      >
                        <div className="item">
                          <Form.Item
                            label="Email id"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the email id",
                              },
                              () => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.reject();
                                  }
                                  if (!emailPatternRegex.test(value)) {
                                    return Promise.reject(
                                      "Please enter the valid email id"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ margin: "0px 5px" }}
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </div>

                    {
                      emailList && emailList.length > 0 && (
                        <ul>
                          <Radio.Group className='w-100' onChange={onChange} value={value}>
                            {emailList.map((item, index) => (
                              <li key={index}>
                                <Radio value={item}>{item}</Radio>
                                <Button onClick={() => deleteItem(index)} type="primary" icon={<DeleteOutlined />} size='middle' />
                              </li>
                            ))}
                          </Radio.Group>
                        </ul>
                      )
                    }
                  </>
                )
              }

              {/* PASSWORD */}
              {
                emailView && (
                  <div className='password-field'>
                    <div>
                      <span>Email: {value}</span>
                    </div>
                    <label>Password</label>
                    <Input value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter the password" />
                  </div>
                )
              }



            </div>
            <div style={{ textAlign: "center", padding: "15px 0px" }}>
              {
                !emailView && (
                  <Button
                    size="large"
                    style={{
                      margin: "0px 10px",
                      background: "#f0f2f5",
                      color: "#000",
                      borderRadius: "6px",
                      width: "100px",
                      border: "0px solid",
                      fontSize: "14px",
                    }}
                    onClick={() => props.submitForm()}
                  >
                    Cancel
                  </Button>
                )
              }
              {
                emailView &&
                (
                  <Button
                    disabled={password === '' || password !== 'SmartWork@1234' ? true : false}
                    size="large"
                    style={{
                      margin: "0px 10px",
                      background: "#0b649d",
                      color: "#fff",
                      borderRadius: "6px",
                      width: "100px",
                      border: "0px solid",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      props.submitForm()
                      props.setClose(false);
                    }}
                  >
                    Submit
                  </Button>
                )
              }
            </div>
          </>
        </div>
      </Modal>
    </div>
  )
}

export default EmailChangePopup